// JQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

// Library
require('./bootstrap');
require('slick-slider')
require('venobox')

$('.slick-slider--services').slick({
    infinite: true,
    arrows: true,
    dots: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
});

$(function() {
    let width = $(window).width();
    if (width <= 991) {
        $('.donate-btn-header a').text('contact')
    }
});